import React, { useRef, useState } from 'react'
import { useStyletron } from 'baseui'
import { Button, SHAPE, SIZE } from 'baseui/button'
import Emoji from './Emoji'

const BenefitsSlider = () => {
  const [css] = useStyletron()
  const [isDown, setIsDown] = useState(false)
  const [left, setLeft] = useState(0)
  const [startX, setStartX] = useState(0)
  const slider = useRef()

  const benefitsContainer = css({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  })

  const benefits = css({
    width: '100%',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    // minHeight: '250px',
    display: 'flex',
    overflowX: 'auto',
    cursor: 'grab',
    /* width */
    '::-webkit-scrollbar': {
      height: '5px',
      width: '5px',
      borderRadius: '5px',
    },

    /* Track */
    '::-webkit-scrollbar-track': {
      background: '#f1f1f1',
      borderRadius: '10px',
    },

    /* Handle */
    '::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '10px',
    },

    /* Handle on hover */
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  })

  const benefitCard = css({
    padding: '1rem',
    margin: '0.6rem 0.5rem',
    minWidth: '280px',
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 1px 4px',
    borderRadius: '10px',
  })

  const lastCard = css({
    padding: '1rem',
    margin: '1rem',
    minWidth: '300px',
    // boxShadow: 'rgba(0, 0, 0, 0.2) 0px 1px 4px',
    borderRadius: '10px',
    textAlign: 'center',
  })

  const mousedown = e => {
    setIsDown(true)
    setStartX(e.pageX - slider.current.offsetLeft)
    setLeft(slider.current.scrollLeft)
  }
  const mouseleave = () => {
    setIsDown(false)
  }

  const mouseup = () => {
    setIsDown(false)
  }

  const mousemove = e => {
    if (!isDown) return
    e.preventDefault()
    const x = e.pageX - slider.current.offsetLeft
    const walk = (x - startX) * 2 //scroll-fast
    slider.current.scrollLeft = left - walk
  }

  const goToApp = () => {
    window.location.href = 'https://app.wa.link/register'
  }
  return (
    <div className={benefitsContainer}>
      <div
        className={benefits}
        ref={slider}
        onMouseDown={mousedown}
        onMouseLeave={mouseleave}
        onMouseUp={mouseup}
        onMouseMove={mousemove}
      >
        <div className={benefitCard}>
          <h3>
            <Emoji symbol="🤩" size={25} /> Taxa de conversão
          </h3>
          <p>
            Quando os usuários vêem um <strong>wa.link</strong>, sabem
            imediatamente que ele os levará ao <strong>WhatsApp</strong> quando
            clicam nele, aumentando a taxa de conversão de sua empresa.
          </p>
        </div>
        <div className={benefitCard}>
          <h3>
            <Emoji symbol="📺" size={25} /> Omnicanal
          </h3>
          <p>
            Os links Premium são perfeitos para as mídias tradicionais (como
            rádio, tv, folhetos, etc.) porque é{' '}
            <strong>
              mais fácil lembrar o nome de uma marca do que um número de
              telefone.
            </strong>
          </p>
        </div>
        <div className={benefitCard}>
          <h3>
            <Emoji symbol="⚙️" size={25} /> Atualizável
          </h3>
          <p>
            Os links Premium podem ser editados sempre que for necessário. Você
            pode{' '}
            <strong>
              modificar a mensagem personalizada, o número de telefone e também
              a URL.
            </strong>
          </p>
        </div>
        <div className={benefitCard}>
          <h3>
            <Emoji symbol="📈" size={25} /> Análise de cliques
          </h3>
          <p>
            Acompanhe o desempenho do seu wa.link em todos os canais com
            analíticos. Veja <strong>o número de cliques</strong> por hora, dia
            ou mês, <strong>fonte</strong>, <strong>sistema operacional</strong>{' '}
            e <strong>local</strong>.
          </p>
        </div>
        <div className={benefitCard}>
          <h3>
            <Emoji symbol="🔎" size={25} /> Pesquisável
          </h3>
          <p>
            As pessoas podem{' '}
            <strong>
              encontrar seu WhatsApp sem saber seu número de telefone
            </strong>
            , apenas o nome ou descrição de seu negócio com nosso mecanismo de
            busca.
          </p>
        </div>
        <div className={benefitCard}>
          <h3>
            <Emoji symbol="🔗" size={25} /> Link Curto
          </h3>
          <p>
            Cada wa.link pode <strong>conter uma mensagem personalizada</strong>{' '}
            sem afetar o comprimento de seu link.
          </p>
        </div>
        <div className={benefitCard}>
          <h3>
            <Emoji symbol="️🛍️" size={25} /> Catálogo
          </h3>
          <p>
            Com o Walink Premium você pode criar{' '}
            <strong>links de marca para seu catálogo</strong> no WhatsApp
            Business.
          </p>
        </div>
        <div className={benefitCard}>
          <h3>
            <Emoji symbol="️🕵️" size={25} /> Privacidade
          </h3>
          <p>
            A privacidade é importante,{' '}
            <strong>Walink não expõe seu número de telefone</strong> ou a
            mensagem personalizada para a web sem consentimento.
          </p>
        </div>
        <div className={benefitCard}>
          <h3>
            <Emoji symbol="📱" size={25} />
            Acessibilidade
          </h3>
          <p>
            Cada link vem com seu próprio <strong>código QR</strong> contendo as
            mesmas informações que o link.
          </p>
        </div>
        <div className={benefitCard}>
          <h3>
            <Emoji symbol="💬" size={25} /> Prioridade
          </h3>
          <p>
            Como o seu número de telefone não é exposto, isso garante que o{' '}
            <strong>usuário chegará a você primeiro no WhatsApp</strong> antes
            de ligar.
          </p>
        </div>
        <div className={benefitCard}>
          <h3>
            <Emoji symbol="🎯" size={25} /> Letras maiúsculas e minúsculas
          </h3>
          <p>
            Os links não diferenciam letras maiúsculas e minúsculas, portanto os
            usuários sempre chegarão até você, não importa se eles clicarem em
            uma variação do link como:
            <br />
            <strong> wa.link/SuaMarca</strong>
            <br />
            <strong> wa.link/SUAMARCA</strong>
            <br />
            <strong> wa.link/suamarca</strong>
          </p>
        </div>

        <div className={lastCard}>
          <h2>Registre-se agora!</h2>
          <p>E compre seu primeiro Walink Premium por 6 USD por ano</p>

          <Button
            onClick={goToApp}
            shape={SHAPE.pill}
            size={SIZE.compact}
            overrides={{
              BaseButton: {
                style: {
                  marginLeft: '1rem',
                  marginRight: '1rem',
                  marginTop: '1rem',
                  marginBottom: '1rem',
                  boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px',
                },
              },
            }}
          >
            <Emoji symbol={'👑'} size={20} /> Cadastre-se
          </Button>
        </div>
      </div>
    </div>
  )
}

export default BenefitsSlider
