import React from 'react'
import { useStyletron } from 'baseui'
import { FiArrowRightCircle, FiArrowDownCircle } from 'react-icons/fi'
import MultilinkPreview from '../components/MultilinkPreview'
import MultilinkDescription from '../components/MultilinkDescription'

const MultilinkSection = () => {
  const [css] = useStyletron()

  const multilinkContainer = css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    '@media screen and (max-width: 800px)': {
      flexDirection: 'column',
    },
  })

  const multilinkSide = css({
    display: 'flex',
    flexBasis: '50%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  })

  const rightArrow = css({
    '@media screen and (max-width: 800px)': {
      display: 'none',
    },
  })

  const downArrow = css({
    '@media screen and (min-width: 801px)': {
      display: 'none',
    },
  })

  return (
    <div>
      <div className={multilinkContainer}>
        <div className={multilinkSide}>
          <MultilinkDescription />
        </div>
        <div
          style={{
            padding: '0 1rem',
          }}
        >
          <div className={rightArrow}>
            <FiArrowRightCircle size="30px" />
          </div>
          <div className={downArrow}>
            <FiArrowDownCircle size="30px" />
          </div>
        </div>
        <div className={multilinkSide}>
          <MultilinkPreview />
        </div>
      </div>
    </div>
  )
}

export default MultilinkSection
