import React from 'react'
import { useStyletron } from 'baseui'
import { Paragraph2 } from 'baseui/typography'
import { ListItem, ListItemLabel } from 'baseui/list'
import { Check } from 'baseui/icon'
import Layout from '../components/layout'
import SEO from '../components/seo'
import CTA from '../components/RegisterCTA'
import PremiumHero from '../components/PremiumHero'
import SearchLink from '../components/SearchLink'
import BenefitsSlider from '../components/BenefitsSlider'
// import Emoji from '../components/Emoji'
import MultilinkSection from '../components/MultilinkSection'
import {
  FiPlusCircle,
  FiEdit,
  FiExternalLink,
  FiBarChart,
  FiTag,
} from 'react-icons/fi'
import { FcSearch } from 'react-icons/fc'
import Pricing from '../components/Pricing'

export default () => {
  const [css] = useStyletron()

  const cardClass = css({
    height: '100%',
    width: '300px',
    margin: '30px auto',
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
    borderRadius: '15px',

    background: 'linear-gradient(-45deg, #23a6d5, #23d5ab)',
  })

  const cardTitle = css({
    textAlign: 'center',
    fontSize: '1.5rem',
    color: '#fff',
    padding: '5px',
  })

  const iconClass = css({
    display: 'flex',
    justifyContent: 'center',
    marginTop: '30px',
  })

  const bannerCard = css({
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
    borderRadius: '15px',
    padding: '1.5rem',
  })

  const iconCenter = css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  })

  return (
    <Layout page="premium" hero={<PremiumHero />}>
      <SEO
        title="Crie seu próprio link WhatsApp com a URL da marca"
        description="Crie seu próprio link WhatsApp com a URL da marca, análise e controle. Com o Walink Premium, aumente o reconhecimento de sua marca e obtenha mais benefícios."
        hreflang={[
          {
            rel: 'alternate',
            hrefLang: 'pt',
            href: 'https://criar.wa.link/premium',
          },
          {
            rel: 'alternate',
            hrefLang: 'en',
            href: 'https://create.wa.link/premium',
          },
          {
            rel: 'alternate',
            hrefLang: 'es',
            href: 'https://crear.wa.link/premium',
          },
          {
            rel: 'alternate',
            hrefLang: 'x-default',
            href: 'https://create.wa.link/premium',
          },
        ]}
      />
      <div>
        <div style={{ margin: '4rem 0' }}>
          <h2
            style={{
              textAlign: 'center',
              fontSize: '1.8rem',
              marginBottom: '2.5rem',
            }}
            id="search"
          >
            Encontre um link WhatsApp de marca para seu negócio
          </h2>
          <SearchLink />
        </div>
      </div>

      <div style={{ marginBottom: '4rem', marginTop: '4rem' }}>
        <div
          className={css({
            textAlign: 'center',
            marginTop: '3rem',
            marginBottom: '3rem',
          })}
        >
          <h2>Por que o Walink Premium é ideal para seu negócio?</h2>
          <Paragraph2>
            Descubra porque nosso serviço é a melhor solução para levar os
            clientes ao seu chat WhatsApp.
          </Paragraph2>
        </div>
        <BenefitsSlider />
      </div>
      <div
        className={css({
          textAlign: 'center',
          marginTop: '4rem',
          marginBottom: '1rem',
          borderTop: '1px solid rgba(0, 0, 0, 0.1)',
          paddingTop: '2rem',
        })}
      >
        <h2>Funções incluídas no Premium</h2>
        <Paragraph2>
          <strong>Pague apenas US$ 6 USD por link anualmente</strong>. Cada link
          inclui as seguintes características:
        </Paragraph2>
      </div>

      <div
        className={css({
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
          justifyContent: 'space-between',
        })}
      >
        <div className={cardClass}>
          <div className={iconClass}>
            <FiTag size={45} />
          </div>
          <h3 className={cardTitle}>Links de marca</h3>
          <ListItem artwork={props => <Check {...props} />}>
            <ListItemLabel>
              wa.link/<strong>SuaEmpresa</strong>
            </ListItemLabel>
          </ListItem>
          <ListItem artwork={props => <Check {...props} />}>
            <ListItemLabel>
              wa.link/<strong>SuaMarca</strong>
            </ListItemLabel>
          </ListItem>
          <ListItem
            overrides={{
              Root: {
                style: {
                  borderRadius: '0 0 15px 15px',
                },
              },
            }}
            artwork={props => <Check {...props} />}
          >
            <ListItemLabel>
              wa.link/<strong>SeuCatalogo</strong>
            </ListItemLabel>
          </ListItem>
        </div>
        <div className={cardClass}>
          <div className={iconClass}>
            <FiEdit size={45} />
          </div>
          <h3 className={cardTitle}>Informações atualizáveis</h3>
          <ListItem artwork={props => <Check {...props} />}>
            <ListItemLabel>
              Altere o número de telefone ou a mensagem personalizada de seu
              link.
            </ListItemLabel>
          </ListItem>
          <ListItem artwork={props => <Check {...props} />}>
            <ListItemLabel>
              Crie links para seu catálogo WhatsApp.
            </ListItemLabel>
          </ListItem>
          <ListItem
            overrides={{
              Root: {
                style: {
                  borderRadius: '0 0 15px 15px',
                },
              },
            }}
            artwork={props => <Check {...props} />}
          >
            <ListItemLabel>Alterar a URL personalizada.</ListItemLabel>
          </ListItem>
        </div>
        <div className={cardClass}>
          <div className={iconClass}>
            <FiBarChart size={45} />
          </div>
          <h3 className={cardTitle}>Analíticos</h3>
          <ListItem artwork={props => <Check {...props} />}>
            <ListItemLabel>Cliques diários e de hora em hora.</ListItemLabel>
          </ListItem>
          <ListItem artwork={props => <Check {...props} />}>
            <ListItemLabel>Cliques por localização.</ListItemLabel>
          </ListItem>
          <ListItem
            overrides={{
              Root: {
                style: {
                  borderRadius: '0 0 15px 15px',
                },
              },
            }}
            artwork={props => <Check {...props} />}
          >
            <ListItemLabel>Fonte de cliques.</ListItemLabel>
          </ListItem>
        </div>
      </div>
      <div className={iconCenter}>
        <FiPlusCircle size="30px" />
      </div>
      <div style={{ marginBottom: '3rem', marginTop: '2rem' }}>
        <div className={bannerCard}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <h3 style={{ margin: 0 }}>
              Além disso... deixe que os clientes o encontrem!
            </h3>
            <FcSearch size="30px" style={{ marginLeft: '5px' }} />
          </div>
          <Paragraph2>
            Apareça como resultado de nosso{' '}
            <strong>
              <a
                href="https://open.wa.link/search"
                target="_blank"
                rel="noopener noreferrer"
              >
                mecanismo de busca
                <span style={{ marginLeft: '2px' }}>
                  <FiExternalLink size="16" />
                </span>
              </a>
            </strong>
            , para que seus clientes possam encontrar seu WhatsApp apenas por
            conhecer seu nome comercial.
          </Paragraph2>
        </div>
      </div>
      <div
        className={css({
          textAlign: 'center',
          marginTop: '4rem',
          marginBottom: '1rem',
          borderTop: '1px solid rgba(0, 0, 0, 0.1)',
          paddingTop: '2rem',
        })}
      >
        <h3>E mais...</h3>
      </div>
      <div style={{ marginBottom: '3rem', marginTop: '2rem' }}>
        <MultilinkSection />
      </div>
      <Pricing />
      <CTA
        description={
          <>
            Você está a poucos cliques de aumentar suas vendas através do
            WhatsApp.
          </>
        }
        title="Você está pronto para o Premium?"
        currentPage="Premium"
      />
    </Layout>
  )
}
