import React from 'react'
import { useStyletron } from 'baseui'
import { Paragraph2 } from 'baseui/typography'
import { Button, SHAPE, SIZE as ButtonSize } from 'baseui/button'
import Emoji from './Emoji'
import premiumCard from '../images/premium-6-dollars-black.png'

const PremiumHero = () => {
  const [css] = useStyletron()

  const innerHero = css({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap-reverse',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '960px',
    margin: `0 auto`,
    padding: '1rem 0',
  })
  const leftSection = css({
    flex: '2',
    color: '#fff',
    minWidth: '300px',
    marginBottom: '3rem',
    padding: '0 1rem',
  })

  const goToApp = () => {
    window.location.href = 'https://app.wa.link/register'
  }

  return (
    <div className="light-gradient">
      <div
        style={{
          width: '100%',
          minHeight: '320px',
        }}
      >
        <div className={innerHero}>
          <div className={leftSection}>
            <h1 className={css({ fontSize: '2.5rem', color: 'white' })}>
              Walink Premium{' '}
            </h1>
            <Paragraph2 $style={{ color: '#fff', fontSize: '1.3rem' }}>
              <strong>
                Crie, acompanhe e edite seus links WhatsApp de sua marca.
              </strong>{' '}
            </Paragraph2>
            <Paragraph2 $style={{ color: '#fff', fontSize: '1.2rem' }}>
              Os links Premium são a ferramenta perfeita para que sua empresa
              aumente o número de usuários que chegam até você no WhatsApp e
              aumente suas vendas.
            </Paragraph2>

            <Button
              onClick={goToApp}
              shape={SHAPE.pill}
              size={ButtonSize.default}
              overrides={{
                BaseButton: {
                  style: {
                    marginTop: '1rem',
                    marginBottom: '1rem',
                    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px',
                  },
                },
              }}
            >
              <>
                <Emoji symbol={'👑'} size={20} /> Comece agora por $6 USD por
                ano
              </>
            </Button>
          </div>
          <div>
            <img
              src={premiumCard}
              alt="walink analytics"
              className={css({
                maxWidth: '320px',
                maxHeight: '320px',
              })}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PremiumHero
