import React from 'react'
import { useStyletron } from 'baseui'
import { Button, SHAPE, SIZE } from 'baseui/button'
import { Paragraph3 } from 'baseui/typography'
import Emoji from './Emoji'

const MultilinkDescription = () => {
  const [css] = useStyletron()

  const card = css({
    padding: '1rem',
    margin: '0.6rem 0.5rem',
    minWidth: '280px',
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 1px 4px',
    borderRadius: '10px',
  })

  const descriptionButton = css({
    display: 'flex',
    justifyContent: 'center',
  })

  const goToApp = () => {
    window.location.href = 'https://app.wa.link/register'
  }

  return (
    <div className={card}>
      <div
        className={css({
          textAlign: 'center',
          marginBottom: '2rem',
        })}
      >
        <h2>
          <Emoji symbol={'⭐'} size={25} /> Página Multilink:{' '}
        </h2>
      </div>
      <Paragraph3>
        O serviço multilink permite uma página web que agrupa todos os Walinks
        que você deseja sob uma única URL.
      </Paragraph3>
      <Paragraph3>
        Cada link Premium será convertido em um botão que os usuários podem
        clicar facilmente. É a solução perfeita quando você tem várias linhas
        WhatsApp para lojas, agentes, departamentos, catálogo, serviços ou
        produtos.
      </Paragraph3>
      <Paragraph3 style={{ marginBottom: 0 }}>
        Você pode personalizar:
      </Paragraph3>
      <ul className="multilinkBullets">
        <li>
          <Paragraph3 style={{ marginTop: '0', marginBottom: '0' }}>
            Logotipo
          </Paragraph3>
        </li>
        <li>
          <Paragraph3>Nome comercial</Paragraph3>{' '}
        </li>
        <li>
          <Paragraph3>Descrição</Paragraph3>
        </li>
        <li>
          <Paragraph3>Website</Paragraph3>
        </li>
      </ul>

      <Paragraph3>
        Você também pode adicionar sua própria política de privacidade para os
        usuários aceitarem antes que eles cheguem até você.
      </Paragraph3>
      <Paragraph3>
        <small>
          * Este serviço está disponível gratuitamente para clientes com 2 ou
          mais links em seu plano de assinatura.
        </small>
      </Paragraph3>
      <div className={descriptionButton}>
        <Button
          onClick={goToApp}
          shape={SHAPE.pill}
          size={SIZE.compact}
          overrides={{
            BaseButton: {
              style: {
                marginTop: '1rem',
                marginBottom: '1rem',
                boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px',
              },
            },
          }}
        >
          <>
            <Emoji symbol={'🤩'} size={20} /> Eu quero isso agora
          </>
        </Button>
      </div>
    </div>
  )
}

export default MultilinkDescription
